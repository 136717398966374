<template>  
  <div id="app">
    <NuxtLoadingIndicator />
    <NuxtLayout v-if="isLoaded">
      <ElementsToast/>
      <NuxtPage />
    </NuxtLayout> 
    <VehicleLoader v-else/>
    <UNotifications />
    <UModals />
  </div>
</template>

<script setup lang="ts">
import { useThemeColor } from "@/composables/useThemeColor.js";
import { StockAvailabilityStatusCode } from "./types/enums";
import type { SupplierStockStatus } from "./types/interfaces";

const isLoaded = ref<boolean>(false);
const { themeApp } = useAppConfig();
const runtimeConfig = useRuntimeConfig()

const { data: supplierStockStatus} = await useFetch<SupplierStockStatus[]>( `/api/configs/availabilityFilters`, { default: () => []});

const available = supplierStockStatus.value.find(availabitily => availabitily.codeErp === StockAvailabilityStatusCode.Available)
const availableSoon = supplierStockStatus.value.find(availabitily => availabitily.codeErp === StockAvailabilityStatusCode.AvailableSoon)
const notAvailable = supplierStockStatus.value.find(availabitily => availabitily.codeErp === StockAvailabilityStatusCode.NotAvailable)
const availableInLogistics = supplierStockStatus.value.find(availabitily => availabitily.codeErp === StockAvailabilityStatusCode.InLogistics)

themeApp.light.dispo = available && available.color2 ? available.color2 : available && available.color ? available.color : runtimeConfig.public.dispoColor,
themeApp.light.dispoSoon = availableSoon && availableSoon.color2 ? availableSoon.color2 : availableSoon && availableSoon.color ? availableSoon.color : runtimeConfig.public.dispoSoonColor,
themeApp.light.indispo = notAvailable && notAvailable.color2 ? notAvailable.color2 : notAvailable && notAvailable.color ? notAvailable.color : runtimeConfig.public.indispoColor,
themeApp.light.inLogistics = availableInLogistics && availableInLogistics.color2 ? availableInLogistics.color2 : availableInLogistics && availableInLogistics.color ? availableInLogistics.color : runtimeConfig.public.inLogisticsColor,
onMounted(async() => {  
  useThemeColor(themeApp.light);
  isLoaded.value = true;
});
</script>

<style scoped>
#exampleModalLabel {
font-family: Inter;
color: red;
}
span {
font-family: Inter;
}
p {
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 1.25rem;
color: #3B393D;
}
.imgError500 {
width: 60%;
}
.spinner-grow {
width: 3rem;
height: 3rem;
background-color: #F0FAF8;
}
</style>